import React from 'react'

function ContactMap() {
  return (
    

<section className="text-green-400 body-font relative mt-10" name="contact">
<h2 className=" text-3xl z-20">Contact Us</h2>
  <div className="absolute inset-0 bg-green-300">
    <iframe title="map" scrolling="no" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14255.081203385003!2d88.04106826977538!3d26.7197842!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xddb990ed5d3a6d2b!2sOne%20Touch%20Print%20Solution!5e0!3m2!1sen!2snp!4v1653895445472!5m2!1sen!2snp" width="100%" height="100%" frameborder="0"></iframe>
  </div>
  <div className="container px-5 py-24 mx-auto flex z-0">
    <div className="lg:w-1/3 md:w-1/2 bg-white rounded-lg p-8 flex flex-col md:ml-auto w-full mt-10 md:mt-0 relative z-10 shadow-md">
      <h2 className="text-green-400 text-lg mb-1 font-medium title-font">Reach to Us</h2>
      <form className="cf" action="https://formspree.io/f/mayvaorj"
  method="POST">
  <div class="half left cf">
    <input type="text" id="input-name" name='client' placeholder="Name" />
    <input type="email" id="input-email" name='email' placeholder="Email address" />
    <input type="text" id="input-subject" name='subject' placeholder="Subject" />
  </div>
  <div class="half right cf">
    <textarea name="message" type="text" id="input-message" placeholder="Message"></textarea>
  </div>  
  <input type="submit" value="Submit" id="input-submit" className='bg-green-400' />
</form>
   
    </div>

  </div>
</section>


  )
}
export default ContactMap
// <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14255.081203385003!2d88.04106826977538!3d26.7197842!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xddb990ed5d3a6d2b!2sOne%20Touch%20Print%20Solution!5e0!3m2!1sen!2snp!4v1653895445472!5m2!1sen!2snp" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>