import SimpleImageSlider from "react-simple-image-slider";
import work1 from '../assets/work-1.jpg'
import work2 from '../assets/work-2.jpg'
import work3 from '../assets/work-3.jpg'


const images = [
work1,
work2,
work3
];

const Slider = () => {
  return (
    <>
    <h1 className="t-title text-green-300 text-4xl">Some of our works</h1>

    <div className="place-content-center m-5">

      <SimpleImageSlider
        width="100vw"
        height="80vh"
        images={images}
        showBullets={false}
        showNavs={true}
        loop
        autoPlay
      />
    </div>
    </>
  );
}
export default Slider;