import React,{useState} from 'react'
import {MenuIcon, XIcon} from '@heroicons/react/outline'
import {Link, animateScroll as scroll} from 'react-scroll'
import logo from '../assets/otps-logo.png'


const Navbar = () => {
    const [nav,setNav] = useState(false)
    const handleClick = () => setNav(!nav)
   return (
    <div className='w-screen h-[100px] fixed drop-shadow-lg bg-green-400 z-20 top-0'>
                <div className="px-2 flex justify-between items-center w-full h-full">
            <div className="logo flex font-bold items-center mr-4 sm:text-4xl">
                <img src={logo} alt="logo" className='h-[100px] w-[150px] mr-5' />
                
            </div>
            <ul className="hidden md:flex text-white">
           <li > <Link  to="home" smooth={true}  duration={500} >
             Home
             </Link>
             </li>
             <li > <Link  to="about" smooth={true} offset={-200} duration={500} >
             About
             </Link>
             </li>
             <li > <Link  to="services" smooth={true} offset={-200} duration={500} >
             Services
             </Link>
             </li>
             <li > <Link  to="contact" smooth={true} offset={-200} duration={500} >
             Contact Us
             </Link>
             </li>
                    
                    
                </ul>
            <div className="md:hidden" onClick={handleClick}>
                {!nav ? <MenuIcon className='w-5' ></MenuIcon> : <XIcon className='w-5'></XIcon>}
       
        

            </div>
        </div>
        <ul className={!nav? 'hidden' : "absolute border-zinc-300 w-full px-8 py-5 bg-slate-900"}>
        <li className='border-b-2 border-zinc-200 min-w-full text-white' > <Link  to="home" smooth={true}  duration={500} >
             Home
             </Link>
             </li>
             <li className='border-b-2 border-zinc-200 min-w-full text-white' > <Link  to="about" smooth={true} offset={-200} duration={500} >
             About
             </Link>
             </li>
             <li className='border-b-2 border-zinc-200 min-w-full text-white' > <Link  to="services" smooth={true} offset={-200} duration={500} >
             Services
             </Link>
             </li>
             <li className='border-b-2 border-zinc-200 min-w-full text-white' > <Link  to="contact" smooth={true} offset={-200} duration={500} >
             Contact Us
             </Link>
             </li>
        
        </ul>
    </div>

  )
}

export default Navbar