import AltHero from './AltHero';
import './App.css';
import About from './components/About';
import Slider from './components/carousel';
import Contact from './components/Contact';
import ContactMap from './components/contact_map';
import Footer from './components/Footer';
import Navbar from './components/Navbar';
import Services from './components/Services';
import Testimonials from './components/Testimonials';

function App() {
  return (
  <>
    <Navbar/>
    <AltHero />
    <Services />
    <Slider />
    <About />
    <Testimonials/>
    <ContactMap />
    <Footer />
    
    </>
  );
}

export default App;
