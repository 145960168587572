import React from 'react'

const About = () => {
  return (
    <div name='about' className='w-full my-32'>
        <div className='max-w-[1240px] mx-auto'>
            <div className='text-center'>
                <h2 className='text-5xl font-bold'>Your desires, Our Prints</h2>
                <p className='text-3xl py-6 text-gray-500'>Based on Budhabare,Jhapa we are providing our quality printing services to your buisness and peronal needs</p>
            </div>

            <div className='grid md:grid-cols-3 gap-5 px-2 text-center'>
                <div className='border py-8 rounded-xl shadow-xl' >
                    <p className='text-6xl font-bold text-green-400'>100%</p>
                    <p className='text-gray-400 mt-2'>Trusted</p>
                </div>
                <div  className='border py-8 rounded-xl shadow-xl' >
                    <p className='text-6xl font-bold text-green-400'>24/7</p>
                    <p className='text-gray-400 mt-2'>Customer Support</p>
                </div>
                <div className='border py-8 rounded-xl shadow-xl' >
                    <p className='text-6xl font-bold text-green-400'>20k+</p>
                    <p className='text-gray-400 mt-2'>Satisfied Customers</p>
                </div>
            </div>
        </div>
    </div>
  )
}

export default About