import React from 'react'
import ServiceCard from './ServiceCard'

const Services = () => {
  return (
      <>
      <h1 name='services' className="flex justify-center my-2  sm:text-3xl] md:text-6xl bg-white text-green-300  ">Our Services</h1>
      <div className="rowC content-103 bg-white">
                <ServiceCard name={'Flex Print'} icon={'fa-solid fa-scroll'} />
                <ServiceCard name={'Cup Print'} icon={'fa-solid fa-mug-hot'} />
                <ServiceCard name={'ID Cards'} icon={'fa-solid fa-id-badge'} />
                
            </div>
            <div className="content-103 rowC">
                <ServiceCard name={'Custom Tshirt'} icon={'fa-solid fa-shirt'} />
                <ServiceCard name={'Business Cards'} icon={'fa-solid fa-address-card'} />
                <ServiceCard name={'Wedding Cards'} icon={'fa-solid fa-money-bill'} />
                 
                 </div>
      </>
  )
}

export default Services