import React from 'react'
const Footer = () => {
  return (
    <div name='footer' className='w-full mt-24 bg-green-400 text-white py-3 px-2'>
        <div className='max-w-[1240px] mx-auto grid md:grid-cols-3 border-b-2 border-gray-600'>
            <div>
                <h6 className='font-bold uppercase pt-2'> Solutions</h6>
                <ul>
                    <li className='py-1'>Wedding Cards</li>
                    <li className='py-1'>ID cards</li>
                    <li className='py-1'>Flex Print</li>
                    <li className='py-1'>Hoarding Boards</li>
                    <li className='py-1'>T-shirt Print</li>
                    <li className='py-1'>Digital Prints</li>
                </ul>
            </div>
            <div>
                <h6 className='font-bold uppercase pt-2'> Solutions</h6>
                <ul>
                    <li className='py-1'>Pamplets</li>
                    <li className='py-1'>Bindings</li>
                    <li className='py-1'>Design</li>
                    <li className='py-1'>Advertising</li>
                    <li className='py-1'>Bills</li>
                    <li className='py-1'>Web Design</li>
                </ul>
            </div>
            <div>
                <h6 className='font-bold uppercase pt-2'> Contact</h6>
                <ul>
                    <li className='py-1 m-2 text-2xl'> <i className='fa-solid fa-at'></i> otprintsolution@gmail.com </li>
                    <li className='py-1 m-2 text-2xl'> <i className='fa-brands fa-whatsapp'></i> 9824073234</li>
                    <li className='py-1 m-2 text-2xl'> <i className='fa-solid fa-phone'></i> 023-555457</li>
                    <li className='py-1 m-2 text-2xl'> <i className='fa-solid fa-location-pin'></i> Buddhashanti-3, Jhapa</li>


                    
                </ul>

            </div>
            
        </div>
                <h6 className='w-full text-center text-white'>Created with ❤️ and <i className='fa-brands fa-react'></i> by  <a href='https://instagram.com/indra_kh_'>Indra</a></h6>
    </div>
  )
}

export default Footer