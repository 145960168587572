import React, { Component } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import image from '../assets/support.jpg'

export default class Testimonials extends Component {
  render() {
    return (
        <div className="container bg-white text-green-300">
			<h1 className="t-title text-3xl">What our client say about us</h1>
      <Carousel className="bg-zinc-200"
        showArrows={true}
        infiniteLoop={true}
        showThumbs={false}
        showStatus={false}
        showIndicators={false}
        autoPlay={true}
        interval={6100}
      >
        <div className="bg-white text-green-400">
          <img src={image} />
          <div className="myCarousel text-green-400">
            <h3 className="text-green-500">Stinky Kafle</h3>
            <h4 className= "text-green-100">Designer</h4>
            <p className="text-green-400">
              It's freeing to be able to catch up on customized news and not be
              distracted by a social media element on the same site
            </p>
          </div>
        </div>

        <div>
          <img src={image} />
          <div className="myCarousel">
            <h3 className="text-green-400">Saurabh Ghimire</h3>
            <h4>Designer</h4>
            <p>
              The simple and intuitive design makes it easy for me use. I highly
              recommend Fetch to my peers.
            </p>
          </div>
        </div>

        <div>
          <img src={image} />
          <div className="myCarousel">
            <h3>Ramesh Khulal</h3>
            <h4>Designer</h4>
            <p>
              I enjoy catching up with Fetch on my laptop, or on my phone when
              I'm on the go!
            </p>
          </div>
        </div>
      </Carousel>
      </div>
    );
  }
}




