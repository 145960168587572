import React from 'react'
import Link from 'react-scroll/modules/components/Link'
import heroImg from './assets/hero.jpg'

function AltHero() {
  return (
    <section className='mt-5'>
    <div className="px-6 py-12 md:px-12 text-gray-800 text-center lg:text-left">
    <div className="container mx-auto xl:px-32">
      <div className="grid lg:grid-cols-2 flex items-center">
        <div className="md:mt-12 lg:mt-0 mb-12 lg:mb-0">
          <div className="block rounded-lg shadow-lg px-6 py-12 md:px-12 lg:-mr-14" style={{background: "hsla(0, 0%, 100%, 0.55)", backdropFilter: "blur(30px)",}}>
            <h1 className="text-4xl md:text-5xl xl:text-5xl font-bold tracking-tight mb-12">All the Printing needs <br /><span className="text-green-600">for your business</span></h1>
            <Link  to="contact" smooth={true} offset={-200} duration={500} >
            <a className="inline-block px-7 py-3 mb-2 md:mb-0 md:mr-2 bg-green-500 text-white font-medium text-sm leading-snug uppercase rounded shadow-md hover:bg-green-600 hover:shadow-lg focus:bg-green-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-green-500 active:shadow-lg transition duration-150 ease-in-out" data-mdb-ripple="true" data-mdb-ripple-color="light" href="#!" role="button">
              Contact Us</a>
              </Link>
          </div>
        </div>
        <div className="md:mb-12 lg:mb-0">
          <img
            src={heroImg}
            className="w-full h-full rounded-lg shadow-lg "
            alt="titanjet flex printer"
          />
        </div>
      </div>
    </div>
  </div>
</section>
  )
}

export default AltHero