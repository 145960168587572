import React from 'react'

const ServiceCard = ({name, icon}) => {
  return (
    <>
   
		{/* <div className="rowC"> */}
			<div className="col-md-4 col-sm-4">
				<div className="service-post">
					<div className="service-content">
						<div className="service-icon">
                        <i className={icon}></i>
                        </div>
						<h3 className="service-title">{name}</h3>
					</div>
					<div className="service-hover"></div>
				</div>
			</div>
            
			
    {/* </div> */}

    </>
  )
}

export default ServiceCard